import Vue, { VNode } from 'vue';

const TeamDetails = Vue.extend({
  data() {
    return {
      teamId: '',
    };
  },

  methods: {
    loadTeamData() {
      this.teamId = this.$route.params.id;
    },
  },

  mounted() {
    this.loadTeamData();
  },

  name: 'team-details',

  render(): VNode {
    return (
      <div>
        Team View {this.teamId}
      </div>
    );
  },

  watch: {
    $route: 'loadTeamData',
  },
});

export default TeamDetails;
